<template>
	<v-sheet
		class="asset"
		id="asset"
		style="height: calc(100vh - 88px)"
		v-if="getPermission('asset::create') || getPermission('asset::edit')"
	>
		<v-row>
			<v-col md="6" class="my-auto">
				<h1 class="custom-header-blue-text m-0">{{ pageTitle() }}</h1>
			</v-col>
			<v-col md="6" class="text-right">
				<v-btn
					:disabled="pageLoading"
					:loading="pageLoading"
					class="white--text mr-2"
					depressed
					color="blue darken-4"
					tile
					v-on:click="updateOrCreate()"
				>
					Save
				</v-btn>
				<v-btn :disabled="pageLoading" depressed tile v-on:click="goBack()"> Cancel </v-btn>
			</v-col>
			<v-col md="12">
				<v-form
					ref="asset-form"
					v-model.trim="formValid"
					lazy-validation
					v-on:submit.stop.prevent="updateOrCreate"
				>
					<v-layout>
						<v-flex md8 class="px-8 py-2" style="max-height: calc(100vh - 155px); overflow-y: scroll">
							<table width="100%" style="table-layout: fixed">
								<tr>
									<td colspan="2">
										<p class="middle-header-background px-4">
											Core Information<br />
											<span>
												<v-icon small>mdi-progress-question</v-icon> Specify the primary description for your
												asset</span
											>
										</p>
									</td>
								</tr>
								<tr>
									<td width="20%" class="pl-4">
										<label for="asset-id" class="btx-label mt-2 required">Asset ID</label>
									</td>
									<td width="80%" class="pr-4">
										<TextInput
											hide-details
											:disabled="pageLoading"
											:loading="pageLoading"
											id="asset-id"
											:rules="[vrules.required(asset.id_number, 'Asset ID')]"
											:class="{
												required: !asset.id_number,
											}"
											placeholder="ID"
											v-model="asset.id_number"
										></TextInput>
									</td>
								</tr>
								<tr>
									<td width="20%" class="pl-4">
										<label for="name" class="btx-label mt-2 required">Asset Name</label>
									</td>
									<td width="80%" class="pr-4">
										<TextInput
											hide-details
											:disabled="pageLoading"
											:loading="pageLoading"
											id="name"
											:rules="[vrules.required(asset.name, 'Name')]"
											:class="{
												required: !asset.name,
											}"
											placeholder="Name"
											v-model="asset.name"
										></TextInput>
									</td>
								</tr>
								<tr>
									<td width="20%" class="pl-4">
										<label for="system-owner" class="btx-label mt-2">System Owner</label>
									</td>
									<td width="80%" class="pr-4">
										<AutoCompleteInput
											hide-details
											:disabled="pageLoading"
											:loading="pageLoading"
											id="system-owner"
											placeholder="System Owner"
											v-model="asset.system_owner"
											:items="systemOwnerList"
											append-outer-icon="mdi-cog"
											v-on:click:append-outer="manageSystemOwnerDialog = true"
										></AutoCompleteInput>
									</td>
								</tr>
								<tr>
									<td width="20%" class="pl-4">
										<label for="department" class="btx-label mt-2">Department</label>
									</td>
									<td width="80%" class="pr-4">
										<AutoCompleteInput
											hide-details
											:disabled="pageLoading"
											:loading="pageLoading"
											id="department"
											placeholder="Department"
											v-model="asset.department"
											:items="departmentList"
											append-outer-icon="mdi-cog"
											v-on:click:append-outer="manageDepartmentDialog = true"
										></AutoCompleteInput>
									</td>
								</tr>
								<tr>
									<td width="20%" class="pl-4">
										<label for="manufacturer" class="btx-label mt-2">Manufacturer</label>
									</td>
									<td width="80%" class="pr-4">
										<AutoCompleteInput
											hide-details
											:disabled="pageLoading"
											:loading="pageLoading"
											id="manufacturer"
											placeholder="Manufacturer"
											v-model="asset.manufacturer"
											:items="manufacturerList"
											append-outer-icon="mdi-cog"
											v-on:click:append-outer="manageManufacturerDialog = true"
										></AutoCompleteInput>
									</td>
								</tr>
								<tr>
									<td width="20%" class="pl-4">
										<label for="location" class="btx-label mt-2">Location</label>
									</td>
									<td width="80%" class="pr-4">
										<AutoCompleteInput
											hide-details
											:disabled="pageLoading"
											:loading="pageLoading"
											id="location"
											placeholder="Location"
											v-model="asset.location"
											:items="locationList"
											append-outer-icon="mdi-cog"
											v-on:click:append-outer="manageLocationDialog = true"
										></AutoCompleteInput>
									</td>
								</tr>
								<tr>
									<td width="20%" class="pl-4">
										<label for="model-no" class="btx-label mt-2">Model No.</label>
									</td>
									<td width="80%" class="pr-4">
										<TextInput
											hide-details
											:disabled="pageLoading"
											:loading="pageLoading"
											id="model-no"
											placeholder="Model No."
											v-model="asset.model_no"
										></TextInput>
									</td>
								</tr>
								<tr>
									<td width="20%" class="pl-4">
										<label for="serial-no" class="btx-label mt-2">Serial No.</label>
									</td>
									<td width="80%" class="pr-4">
										<TextInput
											hide-details
											:disabled="pageLoading"
											:loading="pageLoading"
											id="serial-no"
											placeholder="Serial No."
											v-model="asset.serial_no"
										></TextInput>
									</td>
								</tr>
								<tr>
									<td colspan="2">
										<p class="middle-header-background px-4 mt-6">
											Calibration Information<br />
											<span>
												<v-icon small>mdi-progress-question</v-icon> Specify the units marked on an asset that
												is used for measuring</span
											>
										</p>
									</td>
								</tr>
								<tr>
									<td width="20%" class="pl-4">
										<label for="calibration-frequency" class="btx-label mt-2">Calibration Frequency</label>
									</td>
									<td width="80%" class="pr-4">
										<NumberInput
											hide-details
											suffix="Months"
											:disabled="pageLoading"
											:loading="pageLoading"
											id="calibration-frequency"
											placeholder="Calibration Frequency"
											v-model="asset.calibration_frequency"
											v-on:change="updateDates()"
										></NumberInput>
									</td>
								</tr>
								<tr>
									<td width="20%" class="pl-4">
										<label for="last-calibration-date" class="btx-label mt-2 required"
											>Last Calibration Date</label
										>
									</td>
									<td width="80%" class="pr-4">
										<DatePicker
											hide-details
											:disabled="pageLoading"
											:loading="pageLoading"
											id="last-calibration-date"
											placeholder="Last Calibration Date"
											v-model="asset.last_calibration_date"
											v-on:change="updateDates()"
											:rules="[vrules.required(asset.last_calibration_date, 'Last Calibration Date')]"
											:class="{
												required: !asset.last_calibration_date,
											}"
										></DatePicker>
									</td>
								</tr>
								<tr>
									<td width="20%" class="pl-4">
										<label for="next-calibration-date" class="btx-label mt-2 required"
											>Next Calibration Date</label
										>
									</td>
									<td width="80%" class="pr-4">
										<DatePicker
											hide-details
											:disabled="pageLoading"
											:loading="pageLoading"
											id="next-calibration-date"
											placeholder="Next Calibration Date"
											v-model="asset.next_calibration_date"
											:rules="[vrules.required(asset.next_calibration_date, 'Next Calibration Date')]"
											:class="{
												required: !asset.next_calibration_date,
											}"
										></DatePicker>
									</td>
								</tr>
								<tr>
									<td width="20%" class="pl-4">
										<label for="reminder" class="btx-label mt-2">
											Reminder Date
											<TooltipQuestion>
												<template v-slot:text
													>Days Before Admin Should Receive a Notification For The Next Calibration.</template
												>
											</TooltipQuestion>
										</label>
									</td>
									<td width="80%" class="pr-4">
										<DatePicker
											hide-details
											:min-date="asset.last_calibration_date"
											:max-date="asset.next_calibration_date"
											:disabled="pageLoading"
											:loading="pageLoading"
											id="reminder"
											placeholder="Reminder Date"
											v-model="asset.reminder"
										></DatePicker>
									</td>
								</tr>
								<tr>
									<td width="20%" class="pl-4">
										<label for="calibration-point-and-tolerance" class="btx-label mt-2">
											Calibration Point And Tolerance
										</label>
									</td>
									<td width="80%" class="pr-4">
										<TextInput
											hide-details
											:disabled="pageLoading"
											:loading="pageLoading"
											id="calibration-point-and-tolerance"
											placeholder="Calibration Point And Tolerance"
											v-model="asset.calibration_point_and_tolerance"
										></TextInput>
									</td>
								</tr>
								<tr>
									<td width="20%" class="pl-4">
										<label for="cost-centre" class="btx-label mt-2"> Cost Centre </label>
									</td>
									<td width="80%" class="pr-4">
										<AutoCompleteInput
											hide-details
											:disabled="pageLoading"
											:loading="pageLoading"
											id="cost-centre"
											placeholder="Cost Centre"
											v-model="asset.cost_centre"
											:items="costCenterList"
											append-outer-icon="mdi-cog"
											v-on:click:append-outer="manageCostCenterDialog = true"
										></AutoCompleteInput>
									</td>
								</tr>
								<tr>
									<td width="20%" class="pl-4">
										<label for="calibration-location" class="btx-label mt-2">Calibration Location</label>
									</td>
									<td width="80%" class="pr-4">
										<AutoCompleteInput
											hide-details
											:disabled="pageLoading"
											:loading="pageLoading"
											id="calibration-location"
											placeholder="Calibration Location"
											v-model="asset.calibration_location"
											:items="calibrationLocationList"
											append-outer-icon="mdi-cog"
											v-on:click:append-outer="manageCalibrationLocationDialog = true"
										></AutoCompleteInput>
									</td>
								</tr>
								<tr v-if="!uuid">
									<td colspan="2">
										<p class="middle-header-background px-4 mt-6">
											Service Schedule<br />
											<span>
												<v-icon small>mdi-progress-question</v-icon> Specify the service schedule for your
												asset</span
											>
										</p>
									</td>
								</tr>
								<tr v-if="!uuid">
									<td colspan="2">
										<div class="asset-service-schedule">
											<v-tabs
												v-model="scheduleTab"
												background-color="transparent"
												color="blue"
												class="custom-tab-transparent"
												active-class="blue darken-4 text-white"
												hide-slider
											>
												<v-tab
													v-if="false"
													v-on:click="resetSchedule()"
													:disabled="scheduleLoading"
													href="#daily"
												>
													Daily
												</v-tab>
												<v-tab
													v-if="false"
													v-on:click="resetSchedule()"
													:disabled="scheduleLoading"
													href="#weekly"
												>
													Weekly
												</v-tab>
												<v-tab v-on:click="resetSchedule()" :disabled="scheduleLoading" href="#monthly">
													Monthly
												</v-tab>
											</v-tabs>
											<v-tabs-items v-model="scheduleTab">
												<v-tab-item v-if="false" class="daily-schedule" value="daily">
													<div class="px-4 mb-4">
														<v-radio-group
															:disabled="scheduleLoading"
															v-model="daily.type"
															hide-details
															mandatory
														>
															<v-radio value="everyday" color="blue darken-4">
																<template v-slot:label>
																	<label class="btx-label">
																		<v-layout class="align-center">
																			<v-flex>Every</v-flex>
																			<v-flex style="max-width: 100px" class="px-2">
																				<NumberInput
																					:disabled="scheduleLoading || daily.type != 'everyday'"
																					:loading="scheduleLoading"
																					v-model="daily.day"
																					hide-details
																					hide-top-margin
																				></NumberInput>
																			</v-flex>
																			<v-flex>Day</v-flex>
																		</v-layout>
																	</label>
																</template>
															</v-radio>
															<v-radio value="weekday" color="blue darken-4">
																<template v-slot:label>
																	<label class="btx-label">Weekday</label>
																</template>
															</v-radio>
														</v-radio-group>
													</div>
												</v-tab-item>
												<v-tab-item v-if="false" class="weekly-schedule" value="weekly">
													<div class="px-4 mb-4">
														<v-radio-group :disabled="scheduleLoading" hide-details mandatory>
															<v-radio value="everyday" color="blue darken-4">
																<template v-slot:label>
																	<label class="btx-label">
																		<v-layout class="align-center">
																			<v-flex>Every</v-flex>
																			<v-flex style="max-width: 100px" class="px-2">
																				<NumberInput
																					:disabled="scheduleLoading"
																					:loading="scheduleLoading"
																					v-model="weekly.day"
																					hide-details
																					hide-top-margin
																				></NumberInput>
																			</v-flex>
																			<v-flex>Day</v-flex>
																		</v-layout>
																	</label>
																</template>
															</v-radio>
														</v-radio-group>
														<div class="d-flex mt-4">
															<v-checkbox
																:disabled="scheduleLoading"
																:loading="scheduleLoading"
																v-for="(row, index) in weekly.weekdays"
																:key="index"
																v-model="row.status"
																:label="row.label"
																color="blue darken-4"
																class="mt-0 pt-0 mr-4"
																:false-value="false"
																:true-value="true"
																hide-details
															></v-checkbox>
														</div>
													</div>
												</v-tab-item>
												<v-tab-item class="monthly-schedule" value="monthly">
													<div class="px-4 mb-4">
														<v-radio-group
															:disabled="scheduleLoading"
															v-model="monthly.type"
															hide-details
															mandatory
														>
															<v-radio value="first" color="blue darken-4">
																<template v-slot:label>
																	<label class="btx-label">
																		<v-layout class="align-center">
																			<v-flex>Day</v-flex>
																			<v-flex style="max-width: 100px" class="px-2">
																				<NumberInput
																					:disabled="scheduleLoading || monthly.type != 'first'"
																					:loading="scheduleLoading"
																					v-model="monthly.day"
																					hide-details
																					hide-top-margin
																				></NumberInput>
																			</v-flex>
																			<v-flex>of every</v-flex>
																			<v-flex style="max-width: 100px" class="px-2">
																				<NumberInput
																					:disabled="scheduleLoading || monthly.type != 'first'"
																					:loading="scheduleLoading"
																					v-model="monthly.one_month"
																					hide-details
																					hide-top-margin
																				></NumberInput>
																			</v-flex>
																			<v-flex>month(s)</v-flex>
																		</v-layout>
																	</label>
																</template>
															</v-radio>
															<v-radio class="select-calendar" value="second" color="blue darken-4">
																<template v-slot:label>
																	<label class="btx-label">
																		<v-layout class="align-center">
																			<v-flex>The</v-flex>
																			<v-flex class="px-2">
																				<SelectInput
																					:disabled="scheduleLoading || monthly.type != 'second'"
																					:loading="scheduleLoading"
																					placeholder="Day"
																					:items="week_lists"
																					item-text="label"
																					item-value="value"
																					v-model="monthly.weektype"
																					hide-details
																					hide-top-margin
																				></SelectInput>
																			</v-flex>
																			<v-flex class="px-2">
																				<SelectInput
																					:disabled="scheduleLoading || monthly.type != 'second'"
																					:loading="scheduleLoading"
																					placeholder="Weekday"
																					:items="monthly.weekdays"
																					v-model="monthly.weekday"
																					item-text="label"
																					item-value="value"
																					hide-details
																					hide-top-margin
																				></SelectInput>
																			</v-flex>
																			<v-flex>of every</v-flex>
																			<v-flex style="max-width: 100px" class="px-2">
																				<NumberInput
																					:disabled="scheduleLoading || monthly.type != 'second'"
																					:loading="scheduleLoading"
																					v-model="monthly.two_month"
																					hide-details
																					hide-top-margin
																				></NumberInput>
																			</v-flex>
																			<v-flex>month(s)</v-flex>
																		</v-layout>
																	</label>
																</template>
															</v-radio>
														</v-radio-group>
													</div>
												</v-tab-item>
											</v-tabs-items>
											<div class="px-4">
												<v-layout class="align-center mb-4">
													<v-flex style="max-width: 200px">
														<label for="start-date" class="btx-label bold-500">Start Date</label>
														<DatePicker
															:disabled="scheduleLoading"
															:loading="scheduleLoading"
															v-model="recurrence.start_date"
															id="start-date"
															hide-top-margin
															hide-details
														></DatePicker>
													</v-flex>
													<v-flex style="max-width: 150px">
														<label for="start-time" class="btx-label bold-500">Start Time</label>
														<TimePicker
															:disabled="scheduleLoading"
															:loading="scheduleLoading"
															v-model="recurrence.start_time"
															id="start-time"
															hide-top-margin
															hide-details
														></TimePicker>
													</v-flex>
													<v-flex style="max-width: 150px">
														<label for="end-time" class="btx-label bold-500">End Time</label>
														<TimePicker
															:disabled="scheduleLoading"
															:loading="scheduleLoading"
															v-model="recurrence.end_time"
															id="end-time"
															hide-top-margin
															hide-details
														></TimePicker>
													</v-flex>
												</v-layout>
												<v-layout class="align-center">
													<v-flex>
														<label for="end-of-recurrence" class="btx-label bold-500">End of Recurrence</label>
														<v-radio-group
															:disabled="scheduleLoading"
															v-model="recurrence.end_type"
															mandatory
															class="mt-4 pt-0"
														>
															<v-radio value="end_occur" color="blue darken-4">
																<template v-slot:label>
																	<label class="btx-label">
																		<v-layout class="align-center">
																			<v-flex>End After</v-flex>
																			<v-flex style="max-width: 100px" class="px-2">
																				<NumberInput
																					:disabled="scheduleLoading || recurrence.end_type != 'end_occur'"
																					:loading="scheduleLoading"
																					v-model="recurrence.end_occur"
																					hide-details
																					hide-top-margin
																				></NumberInput>
																			</v-flex>
																			<v-flex>Occurrences</v-flex>
																		</v-layout>
																	</label>
																</template>
															</v-radio>
															<v-radio value="end_date" color="blue darken-4">
																<template v-slot:label>
																	<label class="btx-label">
																		<v-layout class="align-center">
																			<v-flex>End After</v-flex>
																			<v-flex class="px-2">
																				<DatePicker
																					:disabled="scheduleLoading || recurrence.end_type != 'end_date'"
																					:loading="scheduleLoading"
																					v-model="recurrence.end_date"
																					hide-top-margin
																					hide-details
																				></DatePicker>
																			</v-flex>
																		</v-layout>
																	</label>
																</template>
															</v-radio>
														</v-radio-group>
													</v-flex>
												</v-layout>
												<v-layout>
													<v-btn
														:disabled="scheduleLoading"
														:loading="scheduleLoading"
														v-on:click="getServiceSchedule()"
														style="min-height: 100%"
														color="blue darken-4 white--text"
														depressed
														tile
														>Get Schedule</v-btn
													>
												</v-layout>
												<v-layout v-if="recurrence.dates.length" class="mt-6">
													<v-flex class="mr-4">
														<div class="blue-grey lighten-5 px-4 py-2">
															{{ recurrence.message }}
														</div>
													</v-flex>
													<div>
														<v-btn
															v-on:click="dialog = true"
															style="width: 100%; min-height: 100%"
															color="blue darken-4 white--text"
															depressed
															tile
															>Show Schedule</v-btn
														>
													</div>
												</v-layout>
											</div>
										</div>
									</td>
								</tr>
								<tr>
									<td colspan="2">
										<p class="middle-header-background px-4 mt-6">
											Extended Information<br />
											<span>
												<v-icon small>mdi-progress-question</v-icon> Specify the vendor and cost details for
												your asset</span
											>
										</p>
									</td>
								</tr>
								<tr>
									<td width="20%" class="pl-4">
										<label for="previous-vendor" class="btx-label mt-2">Previous Vendor</label>
									</td>
									<td width="80%" class="pr-4">
										<AutoCompleteInput
											hide-details
											:disabled="pageLoading"
											:loading="pageLoading"
											id="previous-vendor"
											placeholder="Previous Vendor"
											v-model="asset.previous_vendor"
											:items="previousVendorList"
											append-outer-icon="mdi-cog"
											v-on:click:append-outer="managePreviousVendorDialog = true"
										></AutoCompleteInput>
									</td>
								</tr>
								<tr>
									<td width="20%" class="pl-4">
										<label for="aml-proposal" class="btx-label mt-2"> AML Proposal </label>
									</td>
									<td width="80%" class="pr-4">
										<AutoCompleteInput
											hide-details
											:disabled="pageLoading"
											:loading="pageLoading"
											id="aml-proposal"
											placeholder="AML Proposal"
											v-model="asset.aml_proposal"
											:items="amlProposalList"
											append-outer-icon="mdi-cog"
											v-on:click:append-outer="manageAmlProposalDialog = true"
										></AutoCompleteInput>
									</td>
								</tr>
								<tr>
									<td width="20%" class="pl-4">
										<label for="contract-price-quote-price" class="btx-label mt-2">
											Contract Price/Quote Price (SGD)
										</label>
									</td>
									<td width="80%" class="pr-4">
										<PriceInput
											hide-details
											:disabled="pageLoading"
											:loading="pageLoading"
											id="contract-price-quote-price"
											placeholder="Contract Price/Quote Price"
											v-model="asset.price"
										></PriceInput>
									</td>
								</tr>
								<tr>
									<td width="20%" class="pl-4">
										<label for="bpo-po-number" class="btx-label mt-2"> BPO/PO Number </label>
									</td>
									<td width="80%" class="pr-4">
										<TextInput
											hide-details
											:disabled="pageLoading"
											:loading="pageLoading"
											id="bpo-po-number"
											placeholder="BPO/PO Number"
											v-model="asset.bpo_po_number"
										></TextInput>
									</td>
								</tr>
								<tr>
									<td width="20%" class="pl-4">
										<label for="frequency-per-year" class="btx-label mt-2"> Frequency Per Year </label>
									</td>
									<td width="80%" class="pr-4">
										<TextInput
											hide-details
											:disabled="pageLoading"
											:loading="pageLoading"
											id="frequency-per-year"
											placeholder="Frequency Per Year"
											v-model="asset.frequency_per_year"
										></TextInput>
									</td>
								</tr>
								<tr>
									<td width="20%" class="pl-4">
										<label for="price-per-annum-sgd" class="btx-label mt-2"> Price Per Annum (SGD) </label>
									</td>
									<td width="80%" class="pr-4">
										<PriceInput
											hide-details
											:disabled="pageLoading"
											:loading="pageLoading"
											id="price-per-annum-sgd"
											placeholder="Price Per Annum"
											v-model="asset.price_per_annum_sgd"
										></PriceInput>
									</td>
								</tr>
								<tr>
									<td width="20%" class="pl-4">
										<label for="process-range" class="btx-label mt-2"> Process Range </label>
									</td>
									<td width="80%" class="pr-4">
										<TextAreaInput
											hide-details
											:disabled="pageLoading"
											:loading="pageLoading"
											id="process-range"
											placeholder="Process Range"
											v-model="asset.process_range"
										></TextAreaInput>
									</td>
								</tr>
								<tr>
									<td width="20%" class="pl-4">
										<label for="comment" class="btx-label mt-2"> Comment </label>
									</td>
									<td width="80%" class="pr-4">
										<TextAreaInput
											hide-details
											:disabled="pageLoading"
											:loading="pageLoading"
											id="comment"
											placeholder="Comment"
											v-model="asset.comment"
										></TextAreaInput>
									</td>
								</tr>
							</table>
						</v-flex>
						<v-flex md4 class="text-center pt-8" style="position: relative">
							<ImageUpload can-change v-model="asset.image" :page-loading="pageLoading"></ImageUpload>
						</v-flex>
					</v-layout>
				</v-form>
			</v-col>
		</v-row>
		<template v-if="manageSystemOwnerDialog">
			<ManageSystemOwner
				:dialog="manageSystemOwnerDialog"
				:list="systemOwnerList"
				v-on:close="manageSystemOwnerDialog = false"
				v-on:success="getOption('system-owner')"
			></ManageSystemOwner>
		</template>
		<template v-if="manageDepartmentDialog">
			<ManageDepartment
				:dialog="manageDepartmentDialog"
				:list="departmentList"
				v-on:close="manageDepartmentDialog = false"
				v-on:success="getOption('department')"
			></ManageDepartment>
		</template>
		<template v-if="manageManufacturerDialog">
			<ManageManufacturer
				:dialog="manageManufacturerDialog"
				:list="manufacturerList"
				v-on:close="manageManufacturerDialog = false"
				v-on:success="getOption('manufacturer')"
			></ManageManufacturer>
		</template>
		<template v-if="manageLocationDialog">
			<ManageLocation
				:dialog="manageLocationDialog"
				:list="locationList"
				v-on:close="manageLocationDialog = false"
				v-on:success="getOption('location')"
			></ManageLocation>
		</template>
		<template v-if="manageCalibrationLocationDialog">
			<ManageCalibrationLocation
				:dialog="manageCalibrationLocationDialog"
				:list="calibrationLocationList"
				v-on:close="manageCalibrationLocationDialog = false"
				v-on:success="getOption('calibration-location')"
			></ManageCalibrationLocation>
		</template>
		<template v-if="manageCostCenterDialog">
			<ManageCostCenter
				:dialog="manageCostCenterDialog"
				:list="costCenterList"
				v-on:close="manageCostCenterDialog = false"
				v-on:success="getOption('cost-center')"
			></ManageCostCenter>
		</template>
		<template v-if="managePreviousVendorDialog">
			<ManagePreviousVendor
				:dialog="managePreviousVendorDialog"
				:list="previousVendorList"
				v-on:close="managePreviousVendorDialog = false"
				v-on:success="getOption('previous-vendor')"
			></ManagePreviousVendor>
		</template>
		<template v-if="manageAmlProposalDialog">
			<ManageAmlProposal
				:dialog="manageAmlProposalDialog"
				:list="amlProposalList"
				v-on:close="manageAmlProposalDialog = false"
				v-on:success="getOption('aml-proposal')"
			></ManageAmlProposal>
		</template>
		<Dialog :dialog="dialog" :dialogWidth="dialogWidth">
			<template v-slot:title>
				<h1 class="custom-header-blue-text m-0">
					Service Schedule ({{ recurrence.dates.length }}) {{ recurrence.start_date_f }} -
					{{ recurrence.end_date_f }}
				</h1>
			</template>
			<template v-slot:body>
				<div>
					<table style="table-layout: fixed">
						<tr
							v-for="(row, index) in recurrence.dates"
							:key="index"
							:class="{ 'is-schedule-changed': isScheduleChanged(index, row) }"
						>
							<td width="40%" class="py-2 px-2">
								<div class="d-flex align-center">
									<v-switch
										v-model="row.status"
										color="blue darken-4"
										inset
										hide-details
										class="mt-0 pt-0 mx-2"
									></v-switch>
									<DatePicker
										v-model="row.date"
										v-on:change="updateWeek(index)"
										style="width: 100%"
										hide-top-margin
										hide-details
									></DatePicker>
								</div>
							</td>
							<td width="30%" class="py-2 px-2">
								<TextInput
									v-model="row['week']"
									placeholder="Weekday"
									readonly
									hide-top-margin
									hide-details
								></TextInput>
							</td>
							<td width="15%" class="py-2 px-2">
								<TimePicker v-model="row['start-time']" hide-top-margin hide-details></TimePicker>
							</td>
							<td width="15%" class="py-2 px-2">
								<TimePicker v-model="row['end-time']" hide-top-margin hide-details></TimePicker>
							</td>
						</tr>
					</table>
				</div>
			</template>
			<template v-slot:action>
				<v-btn
					tile
					depressed
					class="mx-2 custom-grey-border custom-bold-button"
					v-on:click="dialog = false"
				>
					Close
				</v-btn>
			</template>
		</Dialog>
		<Dialog :dialog="confirm_dialog">
			<template v-slot:title>
				<h1 class="custom-header-blue-text m-0">Service Schedule Confirmation</h1>
			</template>
			<template v-slot:body>
				<div>
					<label class="btx-label fw-500"
						>Are you sure, you want to create asset without service schedule?</label
					>
				</div>
			</template>
			<template v-slot:action>
				<v-btn
					tile
					depressed
					v-on:click="
						is_confirmed = true;
						confirm_dialog = false;
						updateOrCreate();
					"
					class="mx-2 custom-grey-border custom-bold-button white--text"
					color="blue darken-4 white--text"
				>
					Yes! Continue
				</v-btn>
				<v-btn
					tile
					depressed
					class="mx-2 custom-grey-border custom-bold-button"
					v-on:click="confirm_dialog = false"
				>
					No! Close
				</v-btn>
			</template>
		</Dialog>
	</v-sheet>
</template>
<script>
import { mapGetters } from "vuex";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
import { SET_LOCAL_DB } from "@/core/services/store/config.module";
import { CreateAsset, UpdateAsset, GetAsset } from "@/core/lib/asset.lib";
import { UploadFiles } from "@/core/lib/upload.lib";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Dialog from "@/view/components/Dialog";
import SelectInput from "@/view/components/SelectInput";
import TextInput from "@/view/components/TextInput";
import TextAreaInput from "@/view/components/TextAreaInput";
import NumberInput from "@/view/components/NumberInput";
import AutoCompleteInput from "@/view/components/AutoCompleteInput";
import PriceInput from "@/view/components/PriceInput";
import DatePicker from "@/view/components/DatePicker";
import ImageUpload from "@/view/components/ImageUpload";
import TooltipQuestion from "@/view/components/TooltipQuestion";
import TimePicker from "@/view/components/TimePicker";
import { toString, map, toSafeInteger, filter, isEqual, cloneDeep } from "lodash";
import ManageSystemOwner from "@/view/components/Manage-System-Owner.vue";
import ManageDepartment from "@/view/components/Manage-Department.vue";
import ManageManufacturer from "@/view/components/Manage-Manufacturer.vue";
import ManageLocation from "@/view/components/Manage-Location.vue";
import ManageCalibrationLocation from "@/view/components/Manage-Calibration-Location.vue";
import ManageCostCenter from "@/view/components/Manage-Cost-Center.vue";
import ManagePreviousVendor from "@/view/components/Manage-Previous-Vendor.vue";
import ManageAmlProposal from "@/view/components/Manage-AML-Proposal.vue";
import ApiService from "@/core/services/api.service";
import MomentJS from "moment-timezone";
import ObjectPath from "object-path";

MomentJS.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
	name: "asset-create",
	title: "Create Asset",
	data() {
		return {
			uuid: null,
			duplicateUUID: null,
			scheduleTab: "daily",
			barcode: null,
			scheduleLoading: false,
			manageSystemOwnerDialog: false,
			manageDepartmentDialog: false,
			manageManufacturerDialog: false,
			manageLocationDialog: false,
			manageCalibrationLocationDialog: false,
			manageCostCenterDialog: false,
			managePreviousVendorDialog: false,
			manageAmlProposalDialog: false,
			stepper: 1,
			formValid: true,
			pageLoading: false,
			dialog: false,
			attachment: [],
			systemOwnerList: [],
			departmentList: [],
			manufacturerList: [],
			locationList: [],
			calibrationLocationList: [],
			costCenterList: [],
			previousVendorList: [],
			amlProposalList: [],
			week_lists: [
				{
					label: "First",
					value: "first",
				},
				{
					label: "Second",
					value: "second",
				},
				{
					label: "Third",
					value: "third",
				},
				{
					label: "Fourth",
					value: "fourth",
				},
				{
					label: "Last",
					value: "last",
				},
			],
			weekdays: [],
			asset: {
				id_number: null,
				name: null,
				system_owner: null,
				department: null,
				manufacturer: null,
				location: null,
				model_no: null,
				serial_no: null,
				calibration_frequency: 1,
				last_calibration_date: null,
				next_calibration_date: null,
				reminder: null,
				calibration_point_and_tolerance: null,
				cost_centre: null,
				calibration_location: null,
				previous_vendor: null,
				aml_proposal: null,
				price: null,
				bpo_po_number: null,
				frequency_per_year: null,
				price_per_annum_sgd: null,
				process_range: null,
				comment: null,
				image: null,
			},
			daily: {
				type: "everyday",
				day: 1,
			},
			weekly: {
				day: 1,
				weekdays: [
					{
						status: false,
						label: "Sunday",
						value: "sunday",
					},
					{
						status: true,
						label: "Monday",
						value: "monday",
					},
					{
						status: false,
						label: "Tuesday",
						value: "tuesday",
					},
					{
						status: false,
						label: "Wednesday",
						value: "wednesday",
					},
					{
						status: false,
						label: "Thursday",
						value: "thursday",
					},
					{
						status: false,
						label: "Friday",
						value: "friday",
					},
					{
						status: false,
						label: "Saturday",
						value: "saturday",
					},
				],
			},
			monthly: {
				type: "first",
				day: 1,
				one_month: 1,
				weektype: "first",
				weekday: "sunday",
				two_month: 1,
				weekdays: [
					{
						status: false,
						label: "Sunday",
						value: "sunday",
					},
					{
						status: false,
						label: "Monday",
						value: "monday",
					},
					{
						status: false,
						label: "Tuesday",
						value: "tuesday",
					},
					{
						status: false,
						label: "Wednesday",
						value: "wednesday",
					},
					{
						status: false,
						label: "Thursday",
						value: "thursday",
					},
					{
						status: false,
						label: "Friday",
						value: "friday",
					},
					{
						status: false,
						label: "Saturday",
						value: "saturday",
					},
				],
			},
			schedule_dates: [],
			recurrence: {
				start_date: MomentJS().format("YYYY-MM-DD"),
				start_time: MomentJS().format("HH:mm"),
				end_time: MomentJS().add(1, "hours").format("HH:mm"),
				end_type: "end_occur",
				message: null,
				dates: [],
				end_occur: 1,
				end_date: null,
				start_date_f: null,
				end_date_f: null,
			},
			is_confirmed: false,
			confirm_dialog: false,
		};
	},
	components: {
		Dialog,
		TimePicker,
		NumberInput,
		TextInput,
		PriceInput,
		ImageUpload,
		DatePicker,
		TooltipQuestion,
		TextAreaInput,
		SelectInput,
		AutoCompleteInput,
		ManageAmlProposal,
		ManageLocation,
		ManageManufacturer,
		ManageDepartment,
		ManageSystemOwner,
		ManageCostCenter,
		ManagePreviousVendor,
		ManageCalibrationLocation,
	},
	methods: {
		resetSchedule() {
			this.schedule_dates = [];
			this.recurrence.dates = [];
			this.recurrence.message = null;
			this.recurrence.start_date_f = null;
			this.recurrence.end_date_f = null;
			this.is_confirmed = false;
		},
		updateDates() {
			const last_date = this.asset.last_calibration_date;
			if (last_date) {
				this.asset.next_calibration_date = MomentJS(last_date, "YYYY-MM-DD")
					.add(this.asset.calibration_frequency, "months")
					.format("YYYY-MM-DD");
			}
		},
		isScheduleChanged(index, date) {
			return !isEqual(this.schedule_dates[index], date);
		},
		updateWeek(index) {
			this.recurrence.dates[index].week = MomentJS(this.recurrence.dates[index].date).format("dddd");
		},
		getOption() {
			this.$store.dispatch(SET_LOCAL_DB).then(() => {
				this.initialize();
			});
		},
		limitAttachment(output) {
			if (this.pageLoading) {
				return false;
			}

			this.attachment = output.slice(0, 3);

			if (this.attachment.length <= 0) {
				return false;
			}

			const request = new FormData();

			for (let i = 0; i < this.attachment.length; i++) {
				request.append(`files[${i}]`, this.attachment[i]);
			}

			this.pageLoading = true;

			UploadFiles(request)
				.then((response) => {
					this.asset.attachment = map(response, "path");
				})
				.catch((error) => {
					this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		getServiceSchedule() {
			this.scheduleLoading = true;

			const weekdays = filter(this.weekly.weekdays, { status: true });

			this.resetSchedule();

			const request = {
				type: this.scheduleTab,
				"start-date": this.recurrence.start_date,
				"start-time": this.recurrence.start_time,
				"end-time": this.recurrence.end_time,
				"end-type": this.recurrence.end_type,
				"end-occur": this.recurrence.end_occur,
				"end-date": this.recurrence.end_date,
				"daily-type": this.daily.type,
				"daily-day": this.daily.day,
				"weekly-day": this.weekly.day,
				"weekly-week": map(weekdays, "value"),
				"monthly-type": this.monthly.type,
				"monthly-day": this.monthly.day,
				"monthly-one-month": this.monthly.one_month,
				"monthly-week-type": this.monthly.weektype,
				"monthly-week-day": this.monthly.weekday,
				"monthly-two-month": this.monthly.two_month,
			};

			ApiService.query("asset/schedule", request)
				.then((output) => {
					this.is_confirmed = true;
					const dates = ObjectPath.get(output, "data.dates", []);
					this.schedule_dates = cloneDeep(dates);
					this.recurrence.dates = cloneDeep(dates);
					this.recurrence.message = ObjectPath.get(output, "data.message");
					this.recurrence.start_date_f = ObjectPath.get(output, "data.start_date");
					this.recurrence.end_date_f = ObjectPath.get(output, "data.end_date");
				})
				.catch((error) => {
					this.$emit("error", error);
					this.logError(error);
				})
				.finally(() => {
					this.scheduleLoading = false;
				});
		},
		async updateOrCreate() {
			const _this = this;

			const formErrors = _this.validateForm(_this.$refs["asset-form"]);

			if (formErrors.length) {
				_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
				return false;
			}

			if (!_this.$refs["asset-form"].validate()) {
				return false;
			}

			if (!this.is_confirmed) {
				this.confirm_dialog = true;
				return false;
			}

			const weekdays = filter(this.weekly.weekdays, { status: true });

			const formData = {
				id_number: _this.asset.id_number,
				name: _this.asset.name,
				system_owner: _this.asset.system_owner,
				department: _this.asset.department,
				manufacturer: _this.asset.manufacturer,
				location: _this.asset.location,
				model_no: _this.asset.model_no,
				serial_no: _this.asset.serial_no,
				calibration_frequency: _this.asset.calibration_frequency,
				last_calibration_date: _this.asset.last_calibration_date,
				next_calibration_date: _this.asset.next_calibration_date,
				reminder: _this.asset.reminder,
				calibration_point_and_tolerance: _this.asset.calibration_point_and_tolerance,
				cost_centre: _this.asset.cost_centre,
				calibration_location: _this.asset.calibration_location,
				previous_vendor: _this.asset.previous_vendor,
				aml_proposal: _this.asset.aml_proposal,
				price: _this.asset.price,
				bpo_po_number: _this.asset.bpo_po_number,
				frequency_per_year: _this.asset.frequency_per_year,
				price_per_annum_sgd: _this.asset.price_per_annum_sgd,
				process_range: _this.asset.process_range,
				comment: _this.asset.comment,
				image: _this.asset.image,
				"do-schedule": _this.recurrence.dates.length ? 1 : 0,
				"schedule-dates": _this.recurrence.dates,
				"schedule-type": _this.scheduleTab,
				"schedule-start-date": _this.recurrence.start_date,
				"schedule-start-time": _this.recurrence.start_time,
				"schedule-end-time": _this.recurrence.end_time,
				"schedule-end-type": _this.recurrence.end_type,
				"schedule-end-occur": _this.recurrence.end_occur,
				"schedule-end-date": _this.recurrence.end_date,
				"schedule-daily-type": _this.daily.type,
				"schedule-daily-day": _this.daily.day,
				"schedule-weekly-day": _this.weekly.day,
				"schedule-weekly-week": map(weekdays, "value"),
				"schedule-monthly-type": _this.monthly.type,
				"schedule-monthly-day": _this.monthly.day,
				"schedule-monthly-one-month": _this.monthly.one_month,
				"schedule-monthly-week-type": _this.monthly.weektype,
				"schedule-monthly-week-day": _this.monthly.weekday,
				"schedule-monthly-two-month": _this.monthly.two_month,
				"schedule-message": _this.recurrence.message,
			};

			try {
				_this.pageLoading = true;

				if (_this.uuid) {
					formData["do-schedule"] = 0;
					const { uuid } = await UpdateAsset(_this.uuid, formData);

					_this.$router.replace({
						name: "asset-detail",
						params: { uuid },
						query: { t: new Date().getTime() },
					});

					_this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Asset has been updated." },
					]);
				} else {
					const { uuid } = await CreateAsset(formData);

					_this.$router.replace({
						name: "asset-detail",
						params: { uuid },
						query: { t: new Date().getTime() },
					});

					_this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Asset has been created." },
					]);
				}
			} catch (error) {
				_this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			} finally {
				_this.pageLoading = false;
			}
		},
		getAsset() {
			GetAsset(this.uuid)
				.then((data) => {
					this.is_confirmed = true;
					this.barcode = data.barcode;
					this.asset.id_number = data.id_number;
					this.asset.name = data.name;
					this.asset.system_owner = data.system_owner;
					this.asset.department = data.department;
					this.asset.manufacturer = data.manufacturer;
					this.asset.location = data.location;
					this.asset.model_no = data.model_no;
					this.asset.serial_no = data.serial_no;
					this.asset.calibration_frequency = data.calibration_frequency;
					this.asset.last_calibration_date = data.last_calibration_date;
					this.asset.next_calibration_date = data.next_calibration_date;
					this.asset.reminder = data.reminder;
					this.asset.calibration_point_and_tolerance = data.calibration_point_and_tolerance;
					this.asset.cost_centre = data.cost_centre;
					this.asset.calibration_location = data.calibration_location;
					this.asset.previous_vendor = data.previous_vendor;
					this.asset.aml_proposal = data.aml_proposal;
					this.asset.price = data.price;
					this.asset.bpo_po_number = data.bpo_po_number;
					this.asset.frequency_per_year = data.frequency_per_year;
					this.asset.price_per_annum_sgd = data.price_per_annum_sgd;
					this.asset.process_range = data.process_range;
					this.asset.comment = data.comment;
					this.asset.image = data.image;

					this.$store.dispatch(SET_BREADCRUMB, [
						{ text: "Asset", disabled: true },
						{ text: "Update", disabled: true },
						{ text: this.barcode, disabled: true },
					]);
				})
				.catch((error) => {
					this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		getDuplicateAsset() {
			GetAsset(this.duplicateUUID)
				.then((data) => {
					this.asset.id_number = data.id_number;
					this.asset.name = data.name;
					this.asset.system_owner = data.system_owner;
					this.asset.department = data.department;
					this.asset.manufacturer = data.manufacturer;
					this.asset.location = data.location;
					this.asset.model_no = data.model_no;
					this.asset.serial_no = data.serial_no;
					this.asset.calibration_frequency = data.calibration_frequency;
					this.asset.last_calibration_date = data.last_calibration_date;
					this.asset.next_calibration_date = data.next_calibration_date;
					this.asset.reminder = data.reminder;
					this.asset.calibration_point_and_tolerance = data.calibration_point_and_tolerance;
					this.asset.cost_centre = data.cost_centre;
					this.asset.calibration_location = data.calibration_location;
					this.asset.previous_vendor = data.previous_vendor;
					this.asset.aml_proposal = data.aml_proposal;
					this.asset.price = data.price;
					this.asset.bpo_po_number = data.bpo_po_number;
					this.asset.frequency_per_year = data.frequency_per_year;
					this.asset.price_per_annum_sgd = data.price_per_annum_sgd;
					this.asset.process_range = data.process_range;
					this.asset.comment = data.comment;
					this.asset.image = data.image;

					this.$store.dispatch(SET_BREADCRUMB, [
						{ text: "Asset", disabled: true },
						{ text: "Create", disabled: true },
					]);
				})
				.catch((error) => {
					this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		pageTitle() {
			if (this.uuid) {
				return this.asset.name;
			}
			return "Create new Asset";
		},
		initialize() {
			this.systemOwnerList = this.localDB("service_owner", []);
			this.departmentList = this.localDB("department", []);
			this.manufacturerList = this.localDB("manufacturer", []);
			this.locationList = this.localDB("location", []);
			this.calibrationLocationList = this.localDB("calibration_location", []);
			this.costCenterList = this.localDB("cost_center", []);
			this.previousVendorList = this.localDB("previous_vendor", []);
			this.amlProposalList = this.localDB("aml_proposal", []);
		},
	},
	computed: {
		...mapGetters(["errors", "localDB"]),
		dialogWidth() {
			return toSafeInteger((document.body.clientWidth / 100) * 80);
		},
	},
	mounted() {
		this.initialize();

		this.$store.dispatch(SET_BREADCRUMB, [
			{ text: "Asset", disabled: true },
			{ text: "Create", disabled: true },
		]);

		const { name, params, query } = this.$route;

		this.duplicateUUID = toString(query.duplicate);

		if (name === "asset-update") {
			this.uuid = params.uuid;
			if (!this.uuid) {
				this.goBack();
			}
			this.pageLoading = true;
			this.getAsset();
		} else if (this.duplicateUUID) {
			this.pageLoading = true;
			this.getDuplicateAsset();
		}
	},
	beforeDestroy() {
		this.$store.dispatch(SET_BREADCRUMB, []);
	},
};
</script>
